.disable_wifi {
    opacity: 0.25;
    pointer-events: none;
}

.main_wifi1_det {
    padding: 0px;
    margin: 0px;
    min-height: 102px;
}

.main_wifi1_det li {
    list-style-type: none;
    float: left;
    width: 33.33%;
    margin-bottom: 10px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.main_wifi1_det img {
    margin-right: 10px;
}

.main_wifi1_det span {
/* font-family: "Inter", sans-serif; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #2D3436;
}

.left_view_room li:last-child a {
    display: block;
}

.left_view_room li a {
    display: none;
}
.price_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: red;
    font-size: 10px;
}
.main_select {
    text-align: right;
}



@media (min-width: 640px) and (max-width: 767px){
    .room_show .col-sm-3{
        width: 50%;
    }
}

@media (max-width: 767px) {
    .main_wifi1_det li {
        margin-bottom: 0px;
    }

    .hotel {
        font-size: 22px !important;
    }
    .main_wifi1_det li{
        width: 50%;
    }
    .price_flex {
        margin-top: 15px;
    }
    .select_room {
        padding: 10px 15px !important;
    }
}
@media (min-width:768px) and (max-width:991px){
    .room_show .col-sm-3 {
    width: 50%;
}

}